<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/StaffNeedList' }">企业招聘</el-breadcrumb-item>
      <el-breadcrumb-item>园区企业用工需求</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div v-for="(item, index) in queryTable.table" :key="index">
        <div class="top" v-if="item.corporateInformation">
          <div class="title">
            <div class="line"></div>
            <div class="text">
              {{ item.corporateInformation.companyName }}<span class="time">发布时间：{{ item.corporateInformation.submitTime }}</span>
            </div>
          </div>
          <div class="userInfo">
            <div class="left">
              <i class="el-icon-user icon"></i>联系人<span>{{ item.corporateInformation.name }}</span>
            </div>
            <div class="line"></div>
            <div class="right">
              <i class="el-icon-phone-outline icon"></i>联系电话<span>{{ item.corporateInformation.phone }}</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(v, index) in item.list" :key="index">
            <div class="title">
              <div class="name">{{ v.job_name }}</div>
              <div class="wage">{{ v.salary }}/月</div>
            </div>
            <div class="detail">职位要求：{{ v.job_requirements }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-row style="margin-top: 25px">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :page-size="queryTable.pageSize"
          :total="queryTable.count"
          :current-page.sync="queryTable.page"
          @current-change="queryTable.search()"
        ></el-pagination>
      </el-row>
  </div>
</template>

<script>
import { QueryTable } from '@/assets/js/QueryTable';
export default {
  name: 'policyService',
  data() {
    return {
      queryTable: new QueryTable('corporateinfo', {}),
    };
  },
  methods: {},
  created() {
    // this.$api.getcorporateinfo(this.$route.params.id).then((res) => {
    //   console.log(res);
    //   this.data = res.result.corporateInformation;
    //   this.list = res.result.list;
    // });
    // this.$api.getcorporateinfoList().then((res) => {
    //   this.data = res.rows.length > 0 ? res.rows : this.data;
    // });
    this.queryTable.search()

  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 30px 20px;
    background-color: #fff;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 30px;
      .title {
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 20px;
          background: #4393f8;
          border-radius: 3px;
          margin-right: 10px;
        }
        .text {
          font-size: 26px;
          color: #333333;
          line-height: 31px;
          font-weight: 600;
          .time {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin-left: 8px;
            font-weight: 400;
          }
        }
      }
    }
    .userInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      color: #333333;
      line-height: 20px;
      .icon {
        font-size: 20px;
        line-height: 20px;
      }
      span {
        font-size: 18px;
        color: #135694;
        line-height: 20px;
        font-weight: 600;
        margin-left: 8px;
      }
      .line {
        width: 1px;
        height: 18.5px;
        background-color: #ddd;
        margin-left: 34px;
      }
      .right {
        width: 256px;
        text-align: right;
      }
    }
    .list {
      display: flex;
      margin-bottom: 30px;
      .item {
        width: 378px;
        margin-right: 13px;
        padding: 20px;
        border: 1px solid #cccccc;
        margin-bottom: 30px;
        min-height: 154px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          line-height: 24px;
          padding-bottom: 19.5px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 15.5px;
          .name {
            font-size: 18px;
            color: #333333;
          }
          .wage {
            font-size: 18px;
            color: #f56c6c;
          }
        }
        .detail {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
        }
      }
      .item:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
